<template>
  <view-wrapper title="订单">
    <template #header>
      <a-tabs type="card" v-model:activeKey="tabs.status" @change="tabs.tabClick" class="tabs-card-self">
        <a-tab-pane :key="item.code" :tab="item.message" v-for="item in status" :disabled="table.loading"></a-tab-pane>
      </a-tabs>
    </template>
    <div style="padding: 24px; background: #fff">
      <form-query :model="form.data" @search="form.search">
        <a-row>
          <a-col :span="7">
            <a-form-item label="订单号" label-width="60">
              <a-input v-model:value="form.data.serialNo" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="采购商名称" class="lable-width_one" label-width="80">
              <a-input v-model:value="form.data.tenantShortName" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="下单时间">
              <e-datepicker
                type="range"
                v-model:start="form.data.preparedStartDate"
                v-model:end="form.data.preparedEndDate"
              ></e-datepicker>
            </a-form-item>
          </a-col>
          <a-col :span="3" class="flex justify-end">
            <a-button @click="form.init">重置</a-button>
            <!-- <a-button @click="form.search" style="margin-left: 10px" type="primary">查询</a-button> -->
          </a-col>
        </a-row>
      </form-query>
      <a-spin :spinning="table.loading">
        <div style="margin-top: 8px">
          <div v-if="table.data.length" style="min-height: 600px; overflow: hidden">
            <e-collapse-item
              v-for="(orderItem, index) in table.data"
              :key="orderItem.id"
              :is-expand="index === 0"
              :loading="table.loading"
            >
              <template #title>
                <div class="flex items-center justify-between operation-box">
                  <ul class="flex items-center">
                    <li class="w-[210px] truncate mr-[24px]">
                      <span class="font-bold" style="margin-right: 8px">订单号</span>
                      <span>{{ orderItem.serialNo }}</span>
                    </li>
                    <li class="w-[200px] truncate mr-[24px]">
                      {{
                        orderItem.inquiry.vin
                          ? `${orderItem.inquiry.vehicleBrand}${
                              orderItem.inquiry.vehicleSubBrand ? ' ' + orderItem.inquiry.vehicleSubBrand : ''
                            } ${orderItem.inquiry.vehicleSystem} ${orderItem.inquiry.vehicleYear} ${
                              orderItem.inquiry.vehicleDisplacement
                            }`
                          : orderItem.inquiry.vehicleBrand
                      }}
                    </li>
                    <li class="font-bold text-[16px] w-[100px] truncate">
                      {{ formatMoney(orderItem.amount, '￥') }}
                    </li>
                  </ul>
                  <ul class="flex items-center">
                    <li class="font-bold w-[120px] truncate">{{ orderItem.tenantShortName }}</li>
                    <li class="font-bold w-[100px] truncate ml-[16px]">
                      <span style="margin-right: 8px">{{ orderItem.preparedBy }}</span>
                      <a-tooltip :title="orderItem.cellphone || '暂无'">
                        <PhoneFilled class="cursor-pointer" style="color: #0d8d8d; transform: rotateY(180deg)" />
                      </a-tooltip>
                      <MessageFilled
                        v-if="orderItem.chatUser"
                        @click="webim.open(orderItem.chatUser)"
                        class="cursor-pointer"
                        style="color: #ffd100; margin-left: 8px"
                      />
                    </li>
                  </ul>
                  <ul class="flex items-center">
                    <li class="w-[150px] truncate mr-[24px]">
                      <span class="font-bold" style="margin-right: 8px">下单时间</span>
                      <span>{{ formatDate(orderItem.preparedDatetime) }}</span>
                    </li>

                    <li class="w-[80px] truncate operation-block">
                      <a-button type="link" @click="table.detail.click(orderItem.id)">查看</a-button>
                    </li>
                  </ul>
                </div>
              </template>
              <div style="border-top: 1px solid #0d8d8d">
                <ul
                  class="flex items-center w-full px-[60px] py-[16px] border"
                  style="border-top: none"
                  v-for="goods in orderItem.showPurchaseGoods"
                  :key="goods.id"
                >
                  <li class="w-[130px] mr-[24px] truncate font-bold">
                    {{ goods.quotationGoods.inquiryGoods.name }}
                  </li>
                  <li class="w-[120px] mr-[24px]">
                    <span>{{ goods.quotationGoods.oem }}</span>
                  </li>
                  <li class="w-[100px] font-bold text-[16px] mr-[24px]">
                    {{ `￥${formatNumber(goods.quotationGoods.price, 2)}*${goods.count}` }}
                  </li>
                  <li class="w-[30px] mr-[24px]">
                    {{ goods.quotationGoods.qualityType.message }}
                  </li>
                  <li class="w-[60px] mr-[24px]">
                    {{ !goods.quotationGoods.reserveDays ? '现货' : `订货${goods.quotationGoods.reserveDays}天` }}
                  </li>
                  <li class="w-[150px] mr-[24px]">
                    <span>小计：</span>
                    <span class="font-bold text-16px">{{ formatMoney(goods.amount, '￥') }}</span>
                  </li>
                  <li class="w-[60px]" v-if="!table.loading && tabs.status === 'C'">
                    <a-badge v-if="goods.status.code === 'P'" status="error" :text="goods.status.message" />
                    <a-badge v-else status="success" text="已发货" />
                  </li>
                </ul>
                <div class="more-block" v-if="orderItem.more">
                  <a-button type="link" @click="table.more.click(orderItem)"
                    >显示更多
                    <DownOutlined style="color: #269995" />
                  </a-button>
                </div>
              </div>
            </e-collapse-item>
            <div class="flex justify-end">
              <a-pagination
                v-model:current="table.paging.pageIndex"
                :total="table.paging.itemCount"
                @change="table.changePageIndex"
              />
            </div>
          </div>
          <a-empty v-else />
        </div>
      </a-spin>
    </div>
  </view-wrapper>
</template>

<script lang="ts" setup>
import { reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useAjax, useTable, formatMoney, formatNumber, formatDate, useWebim } from '@vue-mfe/utils'
import {
  Tabs as ATabs,
  TabPane as ATabPane,
  Row as ARow,
  Col as ACol,
  Tooltip as ATooltip,
  Pagination as APagination,
  Empty as AEmpty,
  Badge as ABadge,
  Spin as ASpin
} from 'ant-design-vue'
import { DownOutlined, PhoneFilled, MessageFilled } from '@ant-design/icons-vue'
import ECollapseItem from '@supplier/components/e-collapse-item.vue'
const columns = [
  {
    title: '配件名称',
    width: 180,
    ellipsis: true,
    dataIndex: 'quotationGoods.inquiryGoods.name'
  },
  {
    title: '编码',
    width: 180,
    ellipsis: true,
    dataIndex: 'quotationGoods.oem'
  },
  {
    title: '合计',
    width: 180,
    slots: { customRender: 'calcultor' }
  },
  {
    title: '品质类型',
    width: 100,
    dataIndex: 'quotationGoods.qualityType.message'
  },
  {
    title: '订货周期',
    width: 100,
    slots: { customRender: 'reserveDays' }
  },
  {
    title: '小计',
    align: 'right',
    slots: { customRender: 'amount' }
  }
]

const webim = useWebim()
const router = useRouter()
const route = useRoute()
const inquiryId = route.query.inquiryId
const { data: status } = useAjax(
  {
    action: 'GET /common/lookup/IQPCSTAT'
  }
)
const tabs = reactive(
  {
    status: 'P',
    tabClick () {
      table.paging.pageIndex = 1
      table.get()
    }
  }
)
const form = reactive(
  {
    data: {
      serialNo: '',
      tenantShortName: '',
      preparedStartDate: '',
      preparedEndDate: ''
    },
    init () {
      form.data.serialNo = ''
      form.data.tenantShortName = ''
      form.data.preparedStartDate = ''
      form.data.preparedEndDate = ''
    },
    search () {
      table.get()
    }
  }
)
const table = useTable(
  {
    ajax: {
      get: {
        action: 'GET /supplier/purchase',
        params: () => Object.assign({ status: tabs.status, inquiryId: inquiryId || '' }, form.data),
        convert: {
          client (data: any) {
            data.forEach(
              (item: any) => {
                const showPurchaseGoods = [] as any[]
                if (item.purchaseGoods.length > 3) {
                  item.more = true
                  item.purchaseGoods.forEach(
                    (inner: Object, index: number) => {
                      if (index < 3) showPurchaseGoods.push({ ...inner })
                    }
                  )
                } else {
                  item.more = false
                  item.purchaseGoods.forEach(
                    (inner: Object) => {
                      showPurchaseGoods.push({ ...inner })
                    }
                  )
                }
                item.showPurchaseGoods = showPurchaseGoods
              }
            )
            return data
          }
        }
      }
    },
    columns,
    detail: {
      click (id: string) {
        router.push({ path: `/supplier/supply/order/${id}` })
      }
    },
    changePageIndex (page: number) {
      table.paging.pageIndex = page
      table.get()
    },
    more: {
      click (order: any) {
        const increment = 3
        const { purchaseGoods, showPurchaseGoods } = order
        const data = [] as any[]
        if (showPurchaseGoods.length + increment >= purchaseGoods.length) {
          order.more = false
          purchaseGoods.forEach((item: any) => data.push({ ...item }))
        } else {
          order.more = true
          purchaseGoods.forEach(
            (item: any, index: number) => {
              if (showPurchaseGoods.length - 1 + increment > index) data.push({ ...item })
            }
          )
        }
        order.showPurchaseGoods = data
      }
    }
  }
)
</script>

<style scoped>
.operation-box .operation-block {
  visibility: hidden;
}
.operation-box:hover .operation-block {
  visibility: visible;
}
.myform :deep(.ant-form-item.lable-width_one) .ant-form-item-label {
  width: 80px;
}
</style>
